import { application } from "./application"

import AutoCompleteController from "./autocomplete_controller"
import BackController from "./back_controller"
import BookmarksController from "./bookmarks_controller"
import DatesController from "./dates_controller"
import FlightSearchController from "./flight_search_controller"
import FlightSearchResultController from "./flight_search_result_controller"
import FormController from "./form_controller"
import ImageUploadController from "./image_upload_controller"
import InviteesController from "./invitees_controller"
import NavigationController from "./navigation_controller"
import SubscribeController from "./subscribe_controller"
import ProfileController from "./profile_controller"
import HwComboboxController from "@josefarias/hotwire_combobox"

application.register("hw-combobox", HwComboboxController)
application.register("autocomplete", AutoCompleteController)
application.register("back", BackController)
application.register("bookmarks", BookmarksController)
application.register("dates", DatesController)
application.register("flight-search", FlightSearchController)
application.register("flight-search-result", FlightSearchResultController)
application.register("form", FormController)
application.register("imageupload", ImageUploadController)
application.register("invitees", InviteesController)
application.register("navigation", NavigationController)
application.register("subscribe", SubscribeController)
application.register("profile", ProfileController)
