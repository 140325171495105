import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleButton", "toggleMarker", "enabledInput", "profileForm" ]

  toggleEnabled() {
    this.toggleButtonTarget.classList.toggle("bg-blue-600")
    this.toggleButtonTarget.classList.toggle("bg-gray-200")
    this.toggleMarkerTarget.classList.toggle("translate-x-5")
    this.toggleMarkerTarget.classList.toggle("translate-x-0")
    this.profileFormTarget.classList.toggle("block")
    this.profileFormTarget.classList.toggle("hidden")

    if(this.enabledInputTarget.value === "true") {
      this.enabledInputTarget.value = "false"
    } else {
      this.enabledInputTarget.value = "true"
    }
  }
}
